import React, { Component } from "react"
import { Helmet } from "react-helmet"
import urljoin from "url-join"
import config from "../../data/SiteConfig"

export default class SEO extends Component {
	render() {
		const replacePath = (path) =>
			path === `/` ? path : path.replace(/\/$/, ``)
		const { postNode, postPath, postSEO, title } = this.props
		let description
		let image = ""
		let postURL
		let author
		let date
		if (postSEO) {
			const postMeta = postNode.frontmatter
			author = postMeta.author
			description = postMeta.description
			date = postMeta.date ? postMeta.date : ""
			if (postMeta.thumbnail) {
				image = postMeta.thumbnail.childImageSharp.fixed.src
			}
			console.log(author)

			postURL = urljoin(config.siteUrl, replacePath(postPath))
		} else {
			description = this.props.description
			image = config.siteLogo
		}

		image = urljoin(config.siteUrl, image)
		const blogURL = urljoin(config.siteUrl, config.pathPrefix)
		const schemaOrgJSONLD = [
			{
				"@context": "http://schema.org",
				"@type": "WebSite",
				url: blogURL,
				name: title,
				alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
			},
		]

		if (postSEO) {
			schemaOrgJSONLD.push(
				{
					"@context": "http://schema.org",
					"@type": "BreadcrumbList",
					itemListElement: [
						{
							"@type": "ListItem",
							position: 1,
							item: {
								"@id": postURL,
								name: title,
								image,
							},
						},
					],
				},
				{
					"@context": "http://schema.org",
					"@type": "BlogPosting",
					url: blogURL,
					name: title,
					author: author ? author : "Gianluca Frongia",
					alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
					publisher: "https://schema.org/Person",
					headline: title,
					datePublished: date,
					image: {
						"@type": "ImageObject",
						url: image,
					},
					description,
				}
			)
		}
		return (
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
				<meta name="image" content={image} />

				<script type="application/ld+json">
					{JSON.stringify(schemaOrgJSONLD)}
				</script>

				<meta property="og:url" content={postSEO ? postURL : blogURL} />
				{postSEO && <meta property="og:type" content="article" />}
				<meta property="og:title" content={title} />
				<meta property="og:description" content={description} />
				<meta property="og:image" content={image} />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content={config.userTwitter} />
				<meta name="twitter:title" content={title} />
				<meta name="twitter:description" content={description} />
				<meta name="twitter:image" content={image} />
			</Helmet>
		)
	}
}
