import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import config from "../../data/SiteConfig"
import SEO from "../components/SEO"

// TODO
// Fix description of pages
export const data = graphql`
	query($slug: String!) {
		markdownRemark(frontmatter: { slug: { eq: $slug } }) {
			html
			excerpt
			frontmatter {
				title
				template
				slug
				description
			}
		}
	}
`

const PageTemplate = ({ data: { markdownRemark: page } }) => {
	return (
		<Layout>
			<SEO
				description={page.frontmatter.description}
				title={`${page.frontmatter.title} - ${config.siteTitle}`}
			/>
			<h1>{page.frontmatter.title}</h1>
			<div dangerouslySetInnerHTML={{ __html: page.html }} />
		</Layout>
	)
}

export default PageTemplate
